if (!window.webshop) window.webshop = {}
if (!frappe.boot) frappe.boot = {  
    "assets_json":{
        "file_uploader.bundle.js": "/assets/frappe/dist/js/file_uploader.bundle.ZLFP4355.js",
    },
   "sysdefaults": {
    "company": "Source1 Distribution",
    "country": "United States",
    "currency": "USD",
    "link_field_results_limit": '50'
}}
